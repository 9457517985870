<script setup lang="ts">
  import { useHistoryStore } from "~/stores/HistoryStore";

  const historyStore = useHistoryStore();

  const layoutCustomProps = useAttrs();
  const canBack = layoutCustomProps.back ?? false;
  const narrow = layoutCustomProps.narrow ?? "yes";
  const backTo = layoutCustomProps.backto;

  const heading = computed(() => {
    return layoutCustomProps.heading;
  });

  const onClickBack = () => {
    if (backTo) {
      historyStore.to(backTo);
      return;
    }
    historyStore.back();
  };

  const isSp = isSpDisplayRef();
  const pageTitleFontSize = computed<string>(() => (isSp.value ? "20px" : "22px"));
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="mx-auto" style="width: 90%">
        <v-icon v-if="canBack" data-testid="back-button" size="40px" class="back" @click="onClickBack"
          >$mdiChevronLeft</v-icon
        >
        <div class="d-flex flex-row justify-center">
          <NuxtLink to="/">
            <AtomsFincsLogoSvg height="30" class="mt-8" />
          </NuxtLink>
        </div>
      </div>
      <div class="mx-auto" width="90%">
        <slot name="header"></slot>
      </div>
      <v-container :class="{ container: narrow == 'yes' }">
        <v-row v-if="!!heading" justify="center" :class="[isSp ? 'my-2' : 'my-8', 'page-title']">
          {{ heading }}
        </v-row>
        <div>
          <slot></slot>
        </div>
      </v-container>
    </NuxtLayout>
  </div>
</template>

<style lang="scss" scoped>
  @media (min-width: 1280px) {
    .container {
      max-width: 1280px !important;
    }
  }

  @media (min-width: 960px) {
    .container {
      max-width: 900px !important;
    }
  }
  .back {
    position: absolute;
    top: 48px;
  }
  .page-title {
    font-size: v-bind(pageTitleFontSize);
    font-style: normal;
    font-weight: 700;
    line-height: 1.8em;
  }
</style>
